<style>
  @import "/css/story.css";
</style>

<template>
    <div class="home">
        <Header></Header>
        <News></News>
        <Biographie></Biographie>

        <Separation></Separation>
        <Services></Services>

        <Footer></Footer>
    </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

import Separation from '@/components/Home/Separation.vue'
//import ModalTempMessage from '@/components/Home/ModalTempMessage.vue'

import Header from '@/components/Home/Header.vue'
import News from '@/components/Home/News.vue'
import Services from '@/components/Home/Services.vue'
import Biographie from '@/components/Home/Biographie.vue'
import Footer from '@/components/Home/Footer.vue'

export default {

    name: 'Home',

    components: {
        Separation,
        Header,
        News,
        Services,
        Biographie,
        Footer
    },
}

</script>
