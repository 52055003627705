<template>
    <div>
        <!-- Three -->
        <section id="three" class="main style1 special">
            <div class="container">
                <div class="row gtr-150">
                    <div class="col-4 col-12-medium">
                        <div class="image fit" style="background-image: url('images/sante.jpg');"></div>
                        <img  class="title" src="images/y_sante.png" alt="" />
                        <p>Nous sommes le reflet de notre alimentation et devons maintenir ou retrouver un équilibre pour devenir acteur de notre santé.</p>
                        <ul class="actions special">
                            <li>
                                <router-link class="button" to="/services/y-sante">
                                    En savoir plus
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-4 col-12-medium">
                        <div class="image fit" style="background-image: url('images/pedagogie.jpg');"></div>
                        <img class="title" src="images/y_pedagogie.png" alt="" />
                        <p>Nous avons le pouvoir de décider de ce que nous mettons dans nos assiettes, mais comment s’y retrouver? Dans un monde où l’industrie agro-alimentaire nous propose un vaste choix de tout et en tout temps.</p>
                        <ul class="actions special">
                          <li>
                              <router-link class="button" to="/services/y-pedagogie">
                                  En savoir plus
                              </router-link>
                          </li>
                        </ul>
                    </div>
                    <div class="col-4 col-12-medium modal-open">
                        <div class="image fit" style="background-image: url('images/sport.jpg');"></div>
                        <img  class="title" src="images/y_sport.png" alt="" />
                        <p>La nutrition a un impact déterminant sur la performance, la préparation d'épreuves sportives, le maintien de la forme et la gestion de poids</p>
                        <ul class="actions special">
                            <li>
                                <router-link class="button" to="/services/y-sport">
                                    En savoir plus
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </section>
    </div>

</template>

<script>

export default {
}

</script>
